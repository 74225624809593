import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LayoutService } from '@service/layout.service';

import { Layout } from '@enum/layout.enum';

import { DmsLayout } from "@layout/dms/dms.layout";
import { EsignLayout } from '@layout/esign/esign.layout';
import {HubspotService} from "@service/hubspot.service";

enum Language {
  ENGLISH = 'en',
  SPANISH = 'es',
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    RouterOutlet,
    EsignLayout,
    DmsLayout,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  readonly Layout = Layout;
  lang = Language.ENGLISH;

  constructor(
    public LayoutService: LayoutService,
    private router: Router,
    private translate: TranslateService,
    private hubspotService: HubspotService
  ) {
    this.translate.setDefaultLang(this.lang);
    this.translate.use(this.lang);
  }

  ngOnInit() {
    if (typeof window === "undefined") return;

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd && this.router.url.includes('pricing')) {
        window.scrollTo(0, 0)
      }

      if (evt instanceof NavigationEnd) {
        this.hubspotService.loadHubSpotChat();
      }
    });
  }
}
