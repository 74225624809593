<main class="lp-pricing" id="pricing">
  <div class="lp-container">

    <h3>Terms Of Service</h3>

    <!-- wp:paragraph -->
    <p><strong>1. Introduction</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>These Terms of Service ("Terms of Service" or "Agreement") govern the use of Hillogy's Services by the Customer ("Customer", "User", "your" or "you"). This Agreement constitutes a legally binding agreement between the User and Hillogy S.L., ("Hillogy", "we", "us" or "our").</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE USING THE SERVICE OFFERED BY Hillogy. Terms in uppercase not defined herein have the same meaning as assigned to them in the Terms of Service and/or the Privacy Policy.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>PLEASE NOTE THAT THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION CLAUSE FOR THE CUSTOMER THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES AND LIMITS THE REMEDIES AVAILABLE TO YOU IN CERTAIN DISPUTES. ANY DISPUTE RELATING TO THE AGREEMENT, YOUR ACCOUNT, OR THE SERVICES PROVIDED BY US MUST BE RESOLVED THROUGH BINDING ARBITRATION ON AN INDIVIDUAL BASIS. PLEASE REFER TO SECTION 13.8 FOR FURTHER DETAILS.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>THIS AGREEMENT ALSO CONTAINS AN AUTOMATIC RENEWAL CLAUSE. PLEASE REVIEW SECTION 8.1 TO UNDERSTAND THE FULL EXTENT OF YOUR RIGHTS AND OBLIGATIONS UNDER THE AUTOMATIC RENEWAL CLAUSE.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a></a><a><strong>2. Acceptance of the Terms of Service</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>WHEN YOU CREATE AND REGISTER AN ACCOUNT WITH US ON OUR WEBSITE BY THE MUTUAL EXECUTION OF ONE OR MORE ORDER FORMS WITH US REFERENCING THESE TERMS (EACH, AN "ORDER"), OR BY ACCESSING OR USING THE SERVICE IN ANY WAY, YOU AGREE TO BE BOUND BY THESE TERMS (TOGETHER WITH ALL ORDER FORMS, THE "AGREEMENT") TO THE EXCLUSION OF ALL OTHER TERMS. YOU REPRESENT AND WARRANT THAT (A) YOU ARE AT LEAST 18 YEARS OLD; (B) YOU HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT; AND (C) IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF AN ORGANIZATION OR ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS, IN WHICH CASE THE TERMS "CUSTOMER," "YOU," AND "YOUR" SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY OR DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY WAY. IF THE TERMS OF THIS AGREEMENT ARE DEEMED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a></a><a><strong>3. Service and License</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.1 Services.</strong> Hillogy provides "Services" to its Customers. For the purposes of the Agreement, "Services" are defined as the software services provided by Hillogy to the Customer and as fully described in an applicable "Order" (as defined in Section 3.3). Subject to Hillogy's receipt of the applicable Fees (as detailed in an Order) from the Customer, Hillogy will make all commercially reasonable efforts to make the Services available to the Customer, in compliance with the terms of this Agreement and an applicable Order.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.2 License to Hillogy Services.</strong> Hillogy grants to the Customer, during the Term (as defined in Section 8.1), a limited, non-exclusive, non-sublicensable, non-transferable, non-assignable right to access and use the Services. Any use of the Services by the Customer is authorized solely for the Customer's internal business and is subject to the Customer's compliance with additional limitations and restrictions specified in an applicable Order.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.3 On-Premise Implementation.</strong> If, pursuant to an applicable Order, the Customer is accessing Hillogy services through an on-premise implementation using a container or other means, the right to access such service will be limited to the time period mentioned in the applicable Order. Absent a contrary agreement in the applicable Order, the right to use the Services through such implementation will also be limited, non-exclusive, non-sublicensable, non-transferable, and non-assignable. Consumption of additional licenses without prior payment will be deemed a material breach of this Agreement, which can only be remedied by proportional payment to Hillogy. In case a Hillogy Partner or Reseller has performed an on-premise implementation for their end customer ("End Customer"), such Partner shall ensure they have rights of the same nature in writing regarding the End Customer's servers.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.4 Order.</strong> For the purposes of this Agreement, an "Order" is defined as a purchase order form or other similar document (including, among others, an online order form) detailing the Services provided herein and the applicable Fees that the Customer must pay to Hillogy. The Order may also provide details about service level terms (if applicable). In case of conflict between the Order and the Terms of Service, the Order shall prevail.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.5 Trial Period.</strong> If the Customer accesses or uses the Service for trial or evaluation purposes as identified in the corresponding Order (the "Trial"), the Customer may use the Services during the Trial, provided that such usage does not exceed the service levels set forth in the corresponding Order. THE CUSTOMER ACKNOWLEDGES AND AGREES THAT THE TRIAL IS PROVIDED "AS IS" AND THE TRIAL IS PROVIDED WITHOUT ANY COMPENSATION, SUPPORT, WARRANTIES, OR REPRESENTATIONS OF ANY KIND. Additionally, the Trial may be subject to certain additional restrictions, limitations, and different terms, all as specified in the corresponding Order. NOTWITHSTANDING ANYTHING CONTAINED HEREIN, FOR TRIAL PURPOSES, THE SERVICE IS PROVIDED "AS IS" WITHOUT ANY REPRESENTATION, WARRANTY, AND/OR INDEMNITIES.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.6 Free Access.</strong> If the Customer accesses or uses the Service for free (the "Limited Use"), the Customer may use the Services during the Limited Use, provided that such usage does not exceed the service levels specified on Hillogy's website with respect to the Limited Use. The Customer acknowledges and agrees that the Limited Use is provided "as is," and the Limited Use is provided without any compensation, support, warranties, or representation of any kind. Additionally, the Customer acknowledges and agrees that Hillogy may terminate the Limited Use at any time and for any reason or modify the applicable terms by posting notice on Hillogy's website.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.7 Support Services. </strong>During the Term, Hillogy may also provide Customers with support services ("Support Services"). The Customer acknowledges that such Support Services may be subject to additional fees as set forth in an applicable Order. If Hillogy provides the Customer with Support Services, it will be detailed in an applicable Order.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>3.8 Service Suspensions.</strong> Hillogy may suspend the Customer's access or use of the Services as follows: (a) immediately if Hillogy reasonably believes the Customer's use of the Services may pose a security risk or adversely affect the Services; (b) immediately if the Customer becomes insolvent, ceases to operate in the ordinary course, makes an assignment for the benefit of creditors or becomes the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding; (c) after thirty (30) days written notice if the Customer breaches this Agreement or any Order (and has not corrected such breach, if correctable, within thirty (30) days of such notice); or (d) the Customer has not paid Hillogy the Fees with respect to the Services.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a></a><a><strong>4. Restrictions and Reservations</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>4.1 Restrictions.</strong> The Customer will use the Services only in accordance with all applicable laws, including, but not limited to, laws related to data protection and privacy (whether applicable in the European Union or otherwise). The Customer agrees not to (and not to allow third parties to): (i) remove or alter any notice or proprietary label of the Services or any part thereof; (ii) reverse engineer, decompile, disassemble, or otherwise attempt to discover the structure, ideas, or underlying algorithms of the Services or any software used to provide or make the Services available; or (iii) rent, resell, or otherwise allow access or use of the Services to third parties.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>4.2 Reservations.</strong> You acknowledge and agree that the Services are provided under license (as described in Section 3.2), and are not sold to you. Except as necessary to access and use the Services, nothing in this Agreement grants title or ownership interest in or to the copyright, patents, trademarks, trade secrets, or other intellectual property rights in or related to the Services, whether expressly, by implication, by estoppel, or otherwise. Hillogy and its licensors and service providers reserve and will retain all their rights, title, and interest in the Services, including all copyright, trademark, and other intellectual property rights contained therein or related thereto, unless expressly granted to you in this Agreement.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>5. Ownership Rights</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>5.1 Ownership.</strong> Hillogy retains all rights, title, and interest, whether pre-existing or otherwise, in and to the Services, and any software, products, works, or other intellectual property created, used, provided, or made available by Hillogy under or in connection with the Services pursuant to this Agreement. Additionally, Hillogy owns the "Service Software," i.e., embedded in the Services. For purposes of this Agreement, "Service Software" is defined as any software code of Hillogy or third parties, computer program, documentation, new versions, updates, enhancements, revisions, and modifications of the foregoing that Hillogy has incorporated into the Services and is the exclusive property of Hillogy, and that Hillogy provides to the Customer under this Agreement. The Customer acknowledges and understands that Hillogy owns all Service Software and all related rights thereto. Subject to the terms and conditions of this Agreement, including, among others, the receipt of all applicable Fees, to the extent Hillogy makes the Service Software available to the Customer, Hillogy grants to the Customer, and the Customer accepts from Hillogy, a limited, non-exclusive, non-transferable, non-assignable, and non-sublicensable license to: run such Service Software solely as necessary to make use of the Services.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>5.2 Restrictions.</strong> The Customer agrees not to: (a) exceed the scope of the licenses granted in Section 5.1; (b) make copies of the Service Software; (c) distribute, sublicense, assign, delegate, lease, rent, sell, share, or otherwise transfer the benefits, use, or rights of the license granted in Section 3.2 and Section 5.1; (d) reverse engineer, decompile, disassemble, or otherwise attempt to learn the source code, structure, or underlying algorithms of the software, except to the extent permitted by applicable law; (e) modify, translate, or create derivative works of the Service Software; (f) remove any copyright, trademark, patent, or other proprietary notice appearing on the Service Software or its copies; or (g) combine or distribute any service software with any third-party software licensed under terms seeking to require either of the software (or any associated intellectual property) to be provided in source code form (e.g., as "open source"), licensed to others to enable the creation or distribution of derivative works, or distributed without charge.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>5.3 Feedback.</strong> The Customer may, from time to time, provide suggestions, comments, or other feedback to Hillogy regarding the Services ("Feedback"). The Customer will grant, and hereby grants, to Hillogy a non-exclusive, worldwide, perpetual, irrevocable, transferable, sublicensable, royalty-free, and fully paid-up license to use and exploit such Feedback for any purpose.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>5.4 Disclaimer.</strong> Nothing in this Agreement will impair Hillogy's right to develop, acquire, license, market, promote, or distribute products, software, or technologies that perform the same or similar functions or otherwise compete with any product, software, or technology that the Customer may develop or produce, market, or distribute.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a></a><a><strong>6. Data Privacy</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>6.1 Hillogy Privacy Policy.</strong> Hillogy's current Privacy Policy is available at <a href="https://hillogy.com/politica-de-privacidad/">https://hillogy.com/politica-de-privacidad/</a> (the "Privacy Policy"), which is hereby incorporated into this Agreement by reference. Please review the Privacy Policy to understand Hillogy's practices regarding the collection, use, and disclosure of information by Hillogy with respect to information collected by Hillogy through the Services.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>6.2 Customer Data.</strong> Customer Data is, will be, and shall remain the property of the Customer. For the purposes of this Agreement, "Customer Data" shall mean any data, information, or other material provided, uploaded, or submitted by the Customer to the Service during the use of the Services. The Customer, not Hillogy, shall be solely responsible for the accuracy, quality, completeness, legality, reliability, suitability, and intellectual property or right to use of all Customer Data. Hereby, the Customer grants Hillogy a non-exclusive, non-transferable, non-sublicensable, global, royalty-free license to use, collect, transfer, and process Customer Data solely for the purpose of providing the Services to the Customer pursuant to the terms of the applicable Order and this Agreement.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>6.3 Derived Data.</strong> <a>The Customer further understands and acknowledges that Hillogy may generate "Derived Data" (as defined below) from Customer Data. For the purposes of this Agreement, "Derived Data" means data sent, collected, or generated by Hillogy from Customer Data in connection with the Customer's use of the Services. Hereby, the Customer agrees and understands that Hillogy may freely use Derived Data for its internal business purposes (including, among others, purposes of improving, testing, operating, promoting, and marketing Hillogy's products and services).</a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>6.4 GDPR Reference.</strong> If Hillogy processes the Customer's "Personal Data" under this Agreement and such Personal Data is subject to the European Union's General Data Protection Regulation ("GDPR"), the Customer may request Hillogy to enter into a Data Processing Agreement ("DPA") with them. Under such DPA, Hillogy will be the "Data Processor" and the Customer will be the "Data Controller." Please note that processing of personal data pursuant to this Agreement will be subject to Hillogy's DPA. You may request access to Hillogy's DPA by sending a request to: <a href="mailto:legal&#64;hillogy.com">legal&#64;hillogy.com</a>. All terms not defined herein, i.e., under this Section 6.4, shall have the same meaning as ascribed to them under Article 4 of the GDPR.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>6.5 Hillogy's Responsibility; Customer Data.</strong> Hillogy will make commercially reasonable efforts to maintain the security and integrity of the Services and all Customer Data controlled by Hillogy. Hillogy shall not be liable for unauthorized access to Customer Data or unauthorized use of the Services unless such access is directly due to gross negligence or intentional misconduct of Hillogy. The Customer is responsible for the use of the Services by any person to whom the Customer has given access to the Services, even if the Customer did not authorize such use. Hillogy reserves the right to retain Customer Data for up to thirty (30) days after termination or expiration of the respective Order. Thereafter, the Customer agrees and acknowledges that Customer Data may be irretrievably deleted.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>7. Fees, Orders, and Taxes</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>7.1 Fees.</strong> The Customer shall pay Hillogy the fees set forth in each applicable Order (collectively, the "Fees"). The Customer acknowledges that they shall not be entitled to return the Services and that the Fees are non-refundable. If Hillogy is required to take legal action due to non-payment of fees, the Customer shall bear all resulting costs of collecting such fees.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>7.2 Additional Services.</strong> The Customer may place Orders for additional Services or extend the term of existing Services by specifying such order details in an Order form agreed upon in writing by the parties referencing the terms and conditions of this Agreement.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>7.3 Taxes.</strong> If Hillogy has a legal obligation (under applicable law) to pay or collect taxes for which the Customer is responsible, including, but not limited to, sales, use, transfer, privilege, special taxes, and all other taxes and duties levied or imposed by reason of the provision of the Services by Hillogy under this Agreement, the corresponding amount shall be invoiced and paid by the Customer, unless the Customer provides Hillogy with a valid tax exemption certificate authorized by the relevant tax authority. All amounts payable to Hillogy under this Agreement shall be made without offset or deduction of any tax, levy, duty, charge, withholding, and/or fees of any nature that may be levied or imposed, including, but not limited to, value-added tax, customs duties, and withholding taxes.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a></a><a><strong>8. Term and Termination</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>8.1 Term.</strong> The term of this Agreement shall commence on the "Effective Date" and, unless terminated earlier in accordance with this Section 8, shall end on the last day of the term specified in a final Order (the "Term"). The Effective Date is defined as the date when the Customer enters into this Agreement, signs an Order, and/or begins using our Services during the Trial. Each Order shall automatically renew at the end of the applicable term, unless either party provides the other with written notice of non-renewal prior to the end of the then-current term.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>8.2 Termination for Breach.</strong> This Agreement and Orders thereunder may be terminated: (a) by either party if the other has materially breached this Agreement, within thirty (30) calendar days following written notice of such breach to the other party if the breach is remediable or immediately after notice if the breach is not remediable; or (b) by Hillogy upon written notice to the Customer if the Customer (i) has made or attempted to make any assignment for the benefit of its creditors or any arrangement with creditors, (ii) has any action or proceeding under any bankruptcy or insolvency laws taken for or against it that has not been dismissed within sixty (60) days.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>8.3 Effect of Termination</strong>. Upon expiration or termination of this Agreement, the Customer shall (i) immediately cease using the Service. Any termination or expiration shall not relieve the Customer of its obligation to pay all accrued Fees prior to termination. If Hillogy terminates the Agreement pursuant to Section 8.2(a), the Customer shall pay Hillogy all Fees for the entire term set forth in the respective Orders.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>9. Confidentiality</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>During the term of this Agreement, either party may provide the other party with confidential and/or proprietary information and materials ("Confidential Information"). All materials and information provided by the disclosing party and identified at the time of disclosure as "Confidential" or bearing a similar legend, and all other information that the receiving party should reasonably have known was Confidential Information of the disclosing party, shall be deemed Confidential Information. This Agreement is Confidential Information, and all pricing terms are Hillogy's Confidential Information. The receiving party shall maintain the confidentiality of the Confidential Information and shall not disclose such information to any third party without the prior written consent of the disclosing party. The recipient shall use the Confidential Information solely for the purposes intended herein. The obligations of this Section shall not apply to any information that: (a) is generally made available to the public without breach of this Agreement, (b) is developed by the receiving party independently of and without reference to the Confidential Information, (c) is disclosed to the receiving party by a third party without restrictions, or (d) was in the lawful possession of the receiving party prior to disclosure and was not obtained by the receiving party directly or indirectly from the disclosing party. The receiving party may disclose Confidential Information as required by law or court order; provided that the receiving party provides prompt written notice of such disclosure and uses its best efforts to limit disclosure. At any time upon written request by the disclosing party, the receiving party shall return to the disclosing party all Confidential Information of the disclosing party in its possession, including, among others, all copies and extracts thereof.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>10. Indemnification</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a><strong>10.1 </strong></a><a><strong>Indemnification by Customer</strong></a><strong>.</strong> Customer will defend, indemnify, and hold Hillogy, its affiliates, suppliers and licensors harmless and each of their respective officers, directors, employees and representatives from and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or relating to any third party claim with respect to: (a) Customer Data; (b) breach of this Agreement or violation of applicable law by Customer; or (c) alleged infringement or misappropriation of third-party's intellectual property rights resulting from Customer Data or caused and contributed by the Customer.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>10.2 Indemnification by Hillogy.</strong> Hillogy will defend, indemnify, and hold the Customer harmless from and against any third party claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from claims by a thirty party that the Customer's use of the Service directly infringes or misappropriates a third party's intellectual property rights (an "Infringement Claim"). Notwithstanding any other provision in this Agreement, Hillogy shall have no obligation to indemnify or reimburse the Customer with respect to any Infringement Claim to the extent arising from: (a) the combination of any Customer Data with the Service; (b) the combination of any products or services, other than those provided by Hillogy to the Customer under this Agreement, with the Service; or (c) non-discretionary designs or specifications provided to Hillogy by the Customer that caused such Infringement Claim.&nbsp; The Customer agrees to reimburse Hillogy for any and all damages, losses, costs and expenses incurred as a result of any of the foregoing actions.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>10.3 Notice of Claim and Indemnity Procedure. </strong>In the event of a claim for which a party seeks indemnification or reimbursement pursuant to this Section 10 (each an "Indemnified Party") and as conditions to indemnification, the Indemnified Party shall: (a) promptly notify the indemnifying party in writing, but in no event later than thirty (30) days after receipt of such claim, along with any additional information necessary for the indemnifying party to evaluate such claim; and (b) the Indemnified Party allows the indemnifying party to assume full control of the defense of the claim, including the hiring of an attorney of its own choice. Upon assuming the defense of a claim with an attorney of its choice, the indemnifying party shall not be responsible for the fees and expenses of additional attorneys engaged by any Indemnified Party. The Indemnified Party shall cooperate with the indemnifying party in the defense of such claim. Notwithstanding the foregoing provisions, the indemnifying party shall have no obligation to indemnify or reimburse for any losses, damages, costs, disbursements, expenses, settlement liability of a claim or other sums paid by any Indemnified Party voluntarily, and without the prior written consent of the indemnifying party to settle a claim. Subject to the maximum liability set forth in Section 12, the provisions of this Section 10 constitute the complete understanding of the parties regarding the respective liability of each party under this Section 10, including, among others, Claims for infringement (including related warranty breach claims) and each party's exclusive obligation to indemnify and reimburse any Indemnified Party.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a><strong>11. Warranty</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>11.1 Warranty.</strong> The Services, when used by the Customer in accordance with the provisions of this Agreement and in accordance with the applicable specifications, will perform, in all material respects, the functions described in the Order, during the term in the corresponding Order.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>11.2 Exclusive Remedies.</strong> The Customer shall inform Hillogy, in accordance with the notification provision of this Agreement, of any breach of the warranty set forth in this Section 11. In the event of a breach of warranty by Hillogy under this Agreement, the sole and exclusive remedy of the Customer, and the entirety of Hillogy's liability, shall be the immediate correction of any material nonconformity to minimize any material adverse effect on the Customer’s business.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>11.3 Disclaimer of Warranty.</strong> Hillogy does not represent or warrant that the operation of the Service (or any part thereof) will be uninterrupted or error-free, or that the Service (or any part thereof) will operate in combination with other hardware, software, systems, or data not provided by Hillogy. CUSTOMER ACKNOWLEDGES THAT, EXCEPT AS EXPRESSLY SET FORTH IN SECTION 11.1, Hillogy MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE OR SERVICES, NOR THEIR CONDITION. Hillogy PROVIDES THE WARRANTY SET FORTH IN SECTION 11.1 AND HEREBY EXPRESSLY EXCLUDES ANY OTHER EXPRESS OR IMPLIED REPRESENTATION OR WARRANTY, WHETHER UNDER COMMON LAW, STATUTES, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY AND ALL WARRANTIES AS TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>12. Limitations of Liability</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>12.1 Exclusion of Incidental and Consequential Damages.</strong> EXCEPT FOR BREACH OF SECTION 9 ("CONFIDENTIALITY") OF THIS AGREEMENT, IN NO EVENT SHALL EITHER PARTY OR ANY OF ITS AFFILIATES, EMPLOYEES, DIRECTORS, OFFICERS, LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY LOSS OF DATA, LOSS OF PROFITS, BUSINESS INTERRUPTION, SUBSTITUTE SERVICES, OR OTHER SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR INDIRECT DAMAGES.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>12.2 Cap on Monetary Liability.</strong> THE TOTAL LIABILITY OF Hillogy FOR ANY CLAIM ARISING UNDER THIS AGREEMENT, WHETHER IN CONTRACT, TORT, OR OTHERWISE, SHALL NOT EXCEED THE AMOUNT OF THE FEES PAID OR PAYABLE BY THE CUSTOMER UNDER THE CORRESPONDING ORDER DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE CLAIM.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><a></a><a><strong>13. Miscellaneous</strong></a></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.1 Compliance with Laws.</strong> The Customer shall comply with all applicable laws and regulations in the use of any Service, including, but not limited to, illegal gathering or collection, or assisting in the gathering or collection of information in violation of any privacy law or regulation. The Customer shall, at its own expense, defend, indemnify, and hold Hillogy harmless from and against any and all claims, losses, liabilities, damages, judgments, government or federal sanctions, costs, and expenses (including attorney's fees) incurred by Hillogy arising out of any claim or assertion by a third party of the Customer’s violation of privacy laws or regulations or any of its agents, officers, directors, or employees.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.2 Assignment.</strong> Neither party may transfer or assign its rights and obligations under this Agreement without the prior written consent of the other party. Notwithstanding the foregoing, Hillogy may transfer and assign its rights under this Agreement without the consent of the other party in connection with a change of control, acquisition, or sale of all or substantially all of its assets.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.3 Force Majeure.</strong> Neither party shall be responsible for failure or delay in performance due to events reasonably beyond its control, including, but not limited to, acts of God, Internet outages, terrorism, war, fires, earthquakes, and other disasters (each, a "Force Majeure"). Notwithstanding the foregoing: (i) the Customer shall be responsible for payment obligations for the Service rendered; and (ii) if a Force Majeure continues for more than thirty (30) days, either party may terminate this agreement by written notice to the other party.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.4 Notice.</strong> All notices between the parties shall be in writing and shall be deemed given if delivered personally or sent by certified mail, or by recognized courier service.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.5 Independent Contractor.</strong> Hillogy is an independent contractor, and both parties agree that no agency, partnership, joint venture, or employment is created as a result of this Agreement. The Customer has no authority of any kind to bind Hillogy.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.6 Marketing.</strong> Hereby, the Customer grants Hillogy the right to identify the Customer as a Hillogy Customer and to use the name, brand, and/or logo of the Customer on Hillogy's website and/or in Hillogy's marketing materials in connection with the Customer’s use of the Service.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13.7 Entire Agreement.</strong> This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all prior agreements, communications, and other understandings, oral or written, related to the subject matter of this Agreement, and all waivers and modifications must be in writing, signed by both parties, unless otherwise provided herein. Any term or provision of this Agreement that is deemed unlawful or unenforceable shall be construed, to the greatest extent possible, to be valid, but in any event, the validity or enforceability of the remainder hereof shall not be affected. In the event of a conflict between this Agreement and the Order document, the terms of this Agreement shall prevail, except as expressly modified in any Order with respect to such Order.</p>
    <!-- /wp:paragraph -->

  </div>
</main>
