import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  private renderer: Renderer2;
  private isLoaded = false; // Track if the script is already loaded

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadHubSpotChat(): void {
    if (this.isLoaded) {
      return; // Prevent duplicate script loading
    }

    const script = this.renderer.createElement('script');
    script.src = 'https://js-eu1.hs-scripts.com/144937418.js';
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'hs-script-loader';
    this.renderer.appendChild(document.body, script);
    this.isLoaded = true; // Mark as loaded
  }
}
