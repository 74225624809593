<main class="lp-pricing" id="pricing">
  <div class="lp-container">

    <h3>Privacy Policy</h3>
    <!-- wp:paragraph -->
    <p><strong>1. What information do we collect?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Personal information you disclose to us:</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: we collect personal information that you provide to us.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We collect personal information that you voluntarily provide to us when you register on the website, express interest in obtaining information about us or our products and services, when you participate in activities on the website or otherwise when you communicate with us.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>The personal information we collect depends on the context of your interactions with us and the website, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Personal information provided by you</strong>. We collect names, phone numbers, email addresses, mailing addresses, job titles, usernames, passwords, contact preferences, contact or authentication data, billing addresses, debit/credit card numbers, and similar information.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Payment data.</strong> We may collect the necessary data to process your payment if you make purchases, such as your payment instrument number (such as a credit card number) and the security code associated with your payment instrument. All payment data is stored by Braintree Payments. You can find links to their privacy notice here: https://www.paypal.com/es/webapps/mpp/ua/privacy-full?locale.x=en_ES</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p>All personal information you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Automatically Collected Information:</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: certain information, such as your Internet Protocol (IP) address and/or browser and device characteristics, is automatically collected when you visit our website.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We automatically collect certain information when you visit, use, or browse the website. This information does not reveal your specific identity (such as your name or contact information), but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referral URLs, device name, country, location, information about how and when you use our website, and other technical information. This information is primarily necessary to maintain the security and operation of our website, and for our internal analytics and reporting purposes.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Like many companies, we also collect information through cookies and similar technologies.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>The information we collect includes:</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Log and Usage Data.</strong> Log and usage data is service-related, diagnostic, usage, and performance information that our servers automatically collect when you access or use our website and that we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings, and information about your activity on the website (such as date and time stamps associated with your usage, pages and files viewed, searches, and other actions you take, device event information (such as system activity, error reports, and hardware settings).</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Device Data.</strong> We collect device data, such as information about your computer, phone, tablet, or other device you use to access the website. Depending on the device used, data from this device may include information such as your IP address (or proxy server), device and app identifiers, location, browser type, hardware model, internet service provider and/or mobile carrier, operating system, and system configuration.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Location Data.</strong> We collect location data, such as information about the location of your device, which may be precise or imprecise. The amount of information we collect depends on the type and settings of the device you use to access the website. For example, we may use GPS and other technologies to collect geolocation data that indicates your current location (based on your IP address). You can choose not to allow us to collect this information by either denying access to the information or disabling Location settings on your device. However, please note that if you choose not to participate, you may not be able to use certain aspects of the Services.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p><strong>Information Collected from Other Sources:</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: we may collect limited data from public databases, marketing partners, social media platforms, and other external sources.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>To enhance our ability to provide you with relevant marketing, offers, and services and to update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, as well as other third parties. This information may include postal addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles, for targeted advertising and event promotion purposes. If you interact with us on a social media platform using your social media account (e.g., Facebook or X), we receive personal information about you, such as your name, email address, and gender. Any personal information we collect from your social media account depends on your social media account privacy settings.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>2. How do we use your information?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: we process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We use the personal information collected through our website for a variety of business purposes as described below. We process your personal information for these purposes based on our legitimate business interests, to enter into or perform a contract with you, with your consent, and/or to comply with our legal obligations. We indicate specific processing reasons we rely on alongside each purpose listed below.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We use the information we collect or receive for:</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Facilitating account creation and login process</strong>. If you choose to link your account with us to a third-party account (such as your Microsoft account), we use the information you allowed us to collect from those third parties to facilitate account creation and the login process for the performance of the contract.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Publishing testimonials.</strong> We publish testimonials on our website that may contain personal information. Before publishing a testimonial, we will obtain your consent to use your name and the testimonial content. If you wish to update or delete your testimonial, please contact us at dpo&#64;hillogy.com and be sure to include your name, testimonial location, and contact information.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Requesting feedback.</strong> We may use your information to request feedback and communicate with you about your use of our website.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Enabling user-to-user communications.</strong> We may use your information to enable user-to-user communications with the consent of each user.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Managing user accounts.</strong> We may use your information to manage our account and keep it in working order.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Sending you administrative information.</strong> We may use your personal information to send you information about products, services, and new features and/or information about changes to our terms, conditions, and policies.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Protecting our Services.</strong> We may use your information as part of our efforts to keep our website safe and secure (for example, for fraud monitoring and prevention).</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Enforcing our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</strong></li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Responding to legal requests and preventing harm.</strong> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Fulfilling and managing your orders.</strong> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the website.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Managing sweepstakes and prize competitions.</strong> We may use your information to manage prize sweepstakes and competitions when you choose to participate in our contests.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Delivering and facilitating the provision of user services.</strong> We may use your information to provide you with the requested service.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Responding to user inquiries/offering user support.</strong> We may use your information to respond to your inquiries and resolve any potential issues you may have with the use of our Services.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Sending you promotional and marketing communications.</strong> We and/or our external marketing partners may use the personal information you provide to us for our marketing purposes if this is in line with your marketing preferences. For example, when you express interest in obtaining information about us or our website, subscribe to marketing programs, or communicate with us otherwise, we collect your personal information. You can opt out of receiving our marketing emails at any time (see "WHAT ARE YOUR PRIVACY RIGHTS" below).</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Delivering targeted advertising.</strong> We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p><strong>3. Will your information be shared with anyone?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: We only share information with your consent, to comply with laws, provide you with services, protect your rights, or fulfill business obligations.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We may process or share the data we have based on the following legal basis:</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Legitimate interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Contract performance:</strong> When we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Legal obligations:</strong> We may disclose your information when we are legally obligated to do so to comply with applicable law, government requests, a court order, judicial proceeding, court order, or legal process, such as in response to a court order or subpoena (including in response to public authorities to comply with national security or law enforcement requirements).</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Vital interests:</strong> We may disclose your information when we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Business transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Vendors, consultants, and other external service providers.</strong> We may share your data with third-party vendors, service providers, contractors, or agents who provide services to us or on our behalf and require access to such information to perform that work. Examples include: payment processing, data analysis, email delivery, web hosting services, customer service, and marketing efforts. We may allow selected third parties to use tracking technology on the website, which will enable them to collect data on our behalf about how you interact with our website over time. This information may be used, among other things, to analyze and track data, determine the popularity of certain content, pages, or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent, or trade your information with third parties for their promotional purposes. We have ongoing contracts with our data processors, which are designed to help protect your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do so. They also will not share your personal information with any organization apart from us. They also agree to protect the data they hold on our behalf and to retain it for the period we instruct.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require them to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li><strong>Business partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p><strong>4. Who will your information be shared with?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: We only share information with the following third parties.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We only share and disclose your information with the following third parties. We have categorized each party so that you can easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the next section titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li>Enabling users to connect to their third-party accounts</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p>Google Account, Microsoft Account</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li>Cloud computing services</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p>Microsoft Azure, Amazon Web Services (AWS), and Google Cloud Platform</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li>Backup and data security</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li>User account registration and authentication</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p>Google OAuth 2.0 and Auth0</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li>Web and mobile analytics</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p>Google Analytics</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>5. Do we use cookies and other tracking technologies?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: We may use cookies and other tracking technologies to collect and store your information.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We may use cookies and similar tracking technologies (such as web beacons and pixels) to access or store information. Specific information on how we use such technologies and how you can reject certain cookies is set out in our Cookie Notice.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>6. How do we handle your social logins?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Our website offers you the ability to register and log in using third-party social media account details (such as your Microsoft logins). When you choose to do this, we will receive certain information from your profile from your social media provider. The profile information we receive may vary depending on the social media provider in question, but often includes your name, email address, profile picture, and other information you choose to make public on such social media platform.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We will use the information we receive only for the purposes described in this privacy notice or otherwise clarified on the relevant website. Please note that we do not control or are responsible for other uses of your personal information by your external social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>7. Is your information transferred internationally?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: We may transfer, store, and process your information in countries other than your own.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Our servers are located in the EU and the United States. If you access our website from outside the EU and the United States, please note that your information may be transferred, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in other countries.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If you reside in the European Economic Area, these countries may not necessarily have data protection laws or other similar comprehensive laws as in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Hillogy complies with the EU-US Privacy Shield Framework (“EU-US Privacy Shield”), the UK’s extension to the EU-US Privacy Shield Agreement, and the Swiss-US Privacy Shield Framework (“Swiss-US Privacy Shield”).</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>This Privacy Policy outlines:</p>
    <!-- /wp:paragraph -->

    <!-- wp:list -->
    <ul class="wp-block-list"><!-- wp:list-item -->
      <li>The types of individuals about whom Hillogy collects personal information</li>
      <!-- /wp:list-item -->

      <!-- wp:list-item -->
      <li>The types of personal information that Hillogy collects</li>
      <!-- /wp:list-item -->

      <!-- wp:list-item -->
      <li>The purposes for which Hillogy processes personal information</li>
      <!-- /wp:list-item -->

      <!-- wp:list-item -->
      <li>The recipients to whom Hillogy discloses personal information</li>
      <!-- /wp:list-item -->

      <!-- wp:list-item -->
      <li>Your privacy rights and how to exercise them; and</li>
      <!-- /wp:list-item -->

      <!-- wp:list-item -->
      <li>How we may be contacted</li>
      <!-- /wp:list-item --></ul>
    <!-- /wp:list -->

    <!-- wp:paragraph -->
    <p><strong>Complaints</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In compliance with the EU-US Privacy Shield and the UK’s extension to the EU-US Privacy Shield Agreement, and the Swiss-US Privacy Shield, Hillogy is committed to resolving complaints about our collection and use of your personal information. EU, UK, and Swiss individuals with inquiries or complaints regarding our handling of personal data received under the EU-US Privacy Shield and the UK’s extension to the EU-US Privacy Shield Agreement and the Swiss-US Privacy Shield should first contact Hillogy at: info&#64;hillogy.com</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Dispute Resolution</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In compliance with the EU-US Privacy Shield and the UK’s extension to the EU-US Privacy Shield Agreement, and the Swiss-US Privacy Shield, Hillogy commits to cooperate and comply respectively with the advice given by the panel established by the EU data protection authorities, the UK Information Commissioner's Office, the Gibraltar Regulatory Authority, and the Swiss Federal Data Protection and Information Commissioner with regard to unresolved complaints concerning our data handling.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Jurisdiction</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>The Federal Trade Commission has jurisdiction over Hillogy’s compliance with the EU-US Privacy Shield and the UK’s extension to the EU-US Privacy Shield Agreement and the Swiss-US Privacy Shield.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Disclosures to public authorities</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Hillogy may, on occasion, be required to disclose the personal information it receives under the EU-US Privacy Shield and the UK’s extension to the EU-US Privacy Shield Agreement and the Swiss-US Privacy Shield in response to legal requests from public authorities, including to meet national security or law enforcement requirements.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Accountability for onward transfers</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Hillogy is responsible for the processing of personal information it receives under the Privacy Shield Principles and subsequently transfers to a third party acting as an agent on its behalf. Hillogy shall remain liable under the Privacy Shield Principles if its agent processes such personal information in a manner inconsistent with the Privacy Shield Principles, unless Hillogy proves that it is not responsible for the event giving rise to the damage.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>8. How long do we retain your information?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: We retain your information for as long as necessary to fulfill the purposes described in this privacy notice, unless otherwise required by law.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We will only retain your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless the law requires or allows for a longer retention period (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than the period of time in which users have an account with us.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information or, if this is not possible (for example, because your personal information has been stored in backup archives), securely store your personal information and isolate it from any further processing until deletion is possible.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>9. How do we keep your information secure?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: Our goal is to protect your personal information through a system of technical and organizational security measures.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our security measures and efforts to protect your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to circumvent our security and access, steal, modify, or otherwise misuse your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our website is at your own risk. You should only access the website within a secure environment.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>10. What are your privacy rights?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: In some regions, such as the European Economic Area, you have rights that allow you greater access and control over your personal information. You may review, change, or terminate your account at any time.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In some regions (such as the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If we rely on your consent to process your personal information, you have the right to withdraw your consent at any time. However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. If you reside in the European Economic Area and believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://edpb.europa.eu/about-edpb/board/members_en">https://edpb.europa.eu/about-edpb/board/members_en</a>.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If you reside in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:info&#64;hillogy.com">info&#64;hillogy.com</a>.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Account Information:</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If at any time you wish to review or change the information in your account or terminate your account, you can contact us using the contact information provided.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If you request to terminate your account, we will deactivate or delete your account and your information from our active databases. However, we may retain certain information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Service, and/or comply with applicable legal requirements.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Cookies and Similar Technologies:</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our website. To opt-out of interest-based advertising by advertisers on our website, visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>Opting Out of Email Marketing:</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will be removed from the marketing email list; however, we may still need to contact you, for example, to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-commercial purposes. To opt-out, you can contact us using the contact information provided.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>11. Controls for Do-Not-Track Features</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to indicate your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technological standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>12. Do We Update This Notice?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>In summary: Yes, we will update this notice as necessary to comply with relevant laws.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed about how we are protecting your information.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>13. How Can You Contact Us About This Notice?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>If you have questions or comments about this notice, you may email us at <a href="mailto:info&#64;hillogy.com">info&#64;hillogy.com</a>.</p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p><strong>14. How Can You Review, Update, or Delete the Data We Collect From You?</strong></p>
    <!-- /wp:paragraph -->

    <!-- wp:paragraph -->
    <p>Depending on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please contact us at: <a href="mailto:info&#64;hillogy.com">info&#64;hillogy.com</a>. We will respond to your request within 30 days.</p>
    <!-- /wp:paragraph -->
  </div>
</main>
