import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";
import {AccordionComponent} from "@component/accordion/accordion.layout";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  standalone: true,
  imports: [CommonModule, RouterLink, AccordionComponent, TranslateModule],
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {

}
